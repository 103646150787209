import { render, staticRenderFns } from "./userCent.vue?vue&type=template&id=1799f3c0&scoped=true"
var script = {}
import style0 from "./userCent.vue?vue&type=style&index=0&id=1799f3c0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1799f3c0",
  null
  
)

export default component.exports